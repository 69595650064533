import apiHelperBank from "@/http/apiHelperBankNoKey";
export default {
  // createSMS
  createSMS(body = {}) {
    return apiHelperBank.post(`/service/sms/send-sms`, body);
  },

  // registrationSMS
  createRegistrationSMS(body = {}) {
    return apiHelperBank.post(`/service/sms/send-registrationsms`, body);
  },

};
