import apiHelper from "@/http/apiHelper";

export default {
  // login
  login(body) {
    // username
    let username = "";

    if ("username" in body) {
      username = body.username;
    }

    // password
    let password = "";

    if ("password" in body) {
      password = body.password;
    }

    return apiHelper.post(
      `/authentication?username=${username}&password=${password}`,
      body
    );
  },

  // createClient
  createClient(body) {
    return apiHelper.post("/clients", body);
  },

  // registerClient
  registerClient(body) {
    return apiHelper.post("/self/registration", body);
  },

  // verifyUser
  verifyUser(body) {
    return apiHelper.post("/self/registration/user", body);
  },

  // addEmail
  addEmail(body, clientId) {
    return apiHelper.post(`/datatables/Contact Details/${clientId}`, body);
  },

  // addIDNumber
  addIDNumber(body, clientId) {
    return apiHelper.post(`/clients/${clientId}/identifiers`, body);
  },

  // submitANewLoanApplication
  submitANewLoanApplication(body) {
    return apiHelper.post(`/loans`, body);
  },

  // approveLoanApplication
  approveLoanApplication(body, loanId) {
    return apiHelper.post(`/loans/${loanId}`, body, {
      command: "approve",
    });
  },

  // rejectLoanApplication
  rejectLoanApplication(body, loanId) {
    return apiHelper.post(`/loans/${loanId}`, body, {
      command: "reject",
    });
  },

  // retrieveAUser
  retrieveAUser(userId) {
    return apiHelper.get(`/users/${userId}`);
  },

  // retrieveAClient
  retrieveAClient(clientId) {
    return apiHelper.get(`/clients/${clientId}`);
  },

  // listClientsSelf
  listClientsSelf() {
    return apiHelper.get(`/self/clients`);
  },

  // getLoans
  getLoans(params = {}, encode = true) {
    return apiHelper.get(`/loans`, params, encode);
  },

  // getLoan
  getLoan(loanId) {
    return apiHelper.get(`/loans/${loanId}`);
  },

  // updateClientDetails
  updateClientDetails(body, clientId) {
    return apiHelper.put(`/clients/${clientId}`, body);
  },

  // updateIdentifier
  updateIdentifier(body, clientId, rowId) {
    return apiHelper.put(`/clients/${clientId}/identifiers/${rowId}`, body);
  },

  // updateContactDetails
  updateContactDetails(body, clientId) {
    return apiHelper.put(`/datatables/Contact Details/${clientId}`, body);
  },

  // updateUserDetails
  updateUserDetails(body, userId) {
    return apiHelper.put(`/users/${userId}`, body);
  },

  // createAllocation
  createAllocation(body, officeId) {
    return apiHelper.post(`/datatables/Allocations/${officeId}`, body);
  },

  // getAllocations
  getAllocations(officeId) {
    return apiHelper.get(`/datatables/Allocations/${officeId}`);
  },

  // updateAllocation
  updateAllocation(body, officeId) {
    return apiHelper.put(`/datatables/Allocations/${officeId}`, body);
  },

  // deleteAllocation
  deleteAllocation(officeId) {
    return apiHelper.delete(`/datatables/Allocations/${officeId}`);
  },

  // bankingDetails
  // createBankingDetail
  createBankingDetail(body, clientId) {
    return apiHelper.post(`/datatables/Banking details/${clientId}`, body);
  },

  // getBankingDetails
  getBankingDetails(clientId) {
    return apiHelper.get(`/datatables/Banking details/${clientId}`);
  },

  // updateBankingDetail
  updateBankingDetail(body, clientId) {
    return apiHelper.put(`/datatables/Banking details/${clientId}`, body);
  },

  // charges
  // createCharge
  createCharge(body) {
    return apiHelper.post(`/charges`, body);
  },

  // getCharges
  getCharges() {
    return apiHelper.get(`/charges`);
  },

  // updateCharge
  updateCharge(body, chargeId) {
    return apiHelper.put(`/charges/${chargeId}`, body);
  },

  // deleteCharge
  deleteCharge(chargeId) {
    return apiHelper.delete(`/charges/${chargeId}`);
  },

  // clientDetails
  // createClientDetail
  createClientDetail(body, clientId) {
    return apiHelper.post(`/datatables/Client Details/${clientId}`, body);
  },

  // getClientDetails
  getClientDetails(clientId) {
    return apiHelper.get(`/datatables/Client Details/${clientId}`);
  },

  // updateClientDetail
  updateClientDetail(body, clientId) {
    return apiHelper.put(`/datatables/Client Details/${clientId}`, body);
  },

  // getClientContactDetails
  getClientContactDetails(clientId) {
    return apiHelper.get(`/datatables/Contact Details/${clientId}`);
  },

  // clients
  // getSelfClientAccountsOverview
  getSelfClientAccountsOverview(clientId) {
    return apiHelper.get(`/self/clients/${clientId}/accounts`);
  },

  // getClientAccountsOverview
  getClientAccountsOverview(clientId) {
    return apiHelper.get(`/clients/${clientId}/accounts`);
  },

  // getClients
  getClients(params = {}) {
    return apiHelper.get(`/clients`, params);
  },

  // getClient
  getClient(clientId, params = {}) {
    return apiHelper.get(`/clients/${clientId}`, params);
  },

  // getGetEntityImageDataURI
  getGetEntityImageDataURI(clientId, params = {}) {
    return apiHelper.gets(`/clients/${clientId}/images`, params);
  },

  // uploadAnImageForAnEntityMultiPartFormData
  uploadAnImageForAnEntityMultiPartFormData(body, clientId) {
    return apiHelper.post(`/clients/${clientId}/images`, body, {}, true);
  },

  // deleteAnImageForAnEntityMultiPartFormData
  deleteAnImageForAnEntityMultiPartFormData(clientId) {
    return apiHelper.delete(`/clients/${clientId}/images`, {}, true);
  },

  // activateClient
  activateClient(body, clientId) {
    return apiHelper.post(`/clients/${clientId}`, body, {
      command: "activate",
    });
  },

  // closeClient
  closeClient(body, clientId) {
    return apiHelper.post(`/clients/${clientId}`, body, {
      command: "close",
    });
  },

  // rejectClient
  rejectClient(body, clientId) {
    return apiHelper.post(`/clients/${clientId}`, body, {
      command: "reject",
    });
  },

  // notes
  // createNote
  createNote(body, resource, resourceId) {
    return apiHelper.post(`/${resource}/${resourceId}/notes`, body);
  },

  // getNotes
  getNotes(resource, resourceId) {
    return apiHelper.get(`/${resource}/${resourceId}/notes`);
  },

  // getNote
  getNote(resource, resourceId, noteId) {
    return apiHelper.get(`/${resource}/${resourceId}/notes/${noteId}`);
  },

  // updateNote
  updateNote(body, resource, resourceId, noteId) {
    return apiHelper.put(`/${resource}/${resourceId}/notes/${noteId}`, body);
  },

  // deleteNote
  deleteNote(resource, resourceId, noteId) {
    return apiHelper.delete(`/${resource}/${resourceId}/notes/${noteId}`);
  },

  // notifications
  // createNotification
  createNotification(body, clientId) {
    return apiHelper.post(`/datatables/Notifications/${clientId}`, body);
  },

  // getNotifications
  getNotifications(clientId) {
    return apiHelper.get(`/datatables/Notifications/${clientId}`);
  },

  // updateNotification
  updateNotification(body, clientId) {
    return apiHelper.put(`/datatables/Notifications/${clientId}`, body);
  },

  // datatables
  // getDatatables
  getDatatables() {
    return apiHelper.get(`/datatables`);
  },

  // downloadTemplates
  // downloadUsersTemplate
  downloadUsersTemplate(params = {}) {
    return apiHelper.get(
      `/users/downloadtemplate`,
      params,
      true,
      "arraybuffer"
    );
  },

  downloadPaymentsTemplate(params = {}) {
    return apiHelper.get(
      `/loans/repayments/downloadtemplate`,
      params,
      true,
      "arraybuffer"
    );
  },

  // downloadClientsTemplate
  downloadClientsTemplate(params = {}) {
    return apiHelper.get(
      `/clients/downloadtemplate`,
      params,
      true,
      "arraybuffer"
    );
  },

  // downloadLoansTemplate
  downloadLoansTemplate(params = {}) {
    return apiHelper.get(
      `/loans/downloadtemplate`,
      params,
      true,
      "arraybuffer"
    );
  },

  // downloadSavingsAccountsTemplate
  downloadSavingsAccountsTemplate(params = {}) {
    return apiHelper.get(
      `/savingsaccounts/downloadtemplate`,
      params,
      true,
      "arraybuffer"
    );
  },

  // downloadGLAccountsTemplate
  downloadGLAccountsTemplate(params = {}) {
    return apiHelper.get(
      `/glaccounts/downloadtemplate`,
      params,
      true,
      "arraybuffer"
    );
  },

  // codes
  // getCodes
  getCodes() {
    return apiHelper.get(`/codes`);
  },

  // codeValues
  // getCodeValues
  getCodeValues(codeId) {
    return apiHelper.get(`/codes/${codeId}/codevalues`);
  },

  // createCodeValue
  createCodeValue(body, codeId) {
    return apiHelper.post(`/codes/${codeId}/codevalues`, body);
  },

  // contributions
  // createContribution
  createContribution(body, clientId) {
    return apiHelper.post(`/datatables/Contributions/${clientId}`, body);
  },

  // getContributions
  getContributions(clientId) {
    return apiHelper.get(`/datatables/Contributions/${clientId}`);
  },

  // updateContribution
  updateContribution(body, clientId, contributionId) {
    return apiHelper.put(
      `/datatables/Contributions/${clientId}/${contributionId}`,
      body
    );
  },

  // deleteContribution
  deleteContribution(clientId, contributionId) {
    return apiHelper.delete(
      `/datatables/Contributions/${clientId}/${contributionId}`
    );
  },

  // employmentVerifications
  // createEmploymentVerification
  createEmploymentVerification(body, clientId) {
    return apiHelper.post(
      `/datatables/Employment verification/${clientId}`,
      body
    );
  },

  // getEmploymentVerifications
  getEmploymentVerifications(clientId) {
    return apiHelper.get(`/datatables/Employment verification/${clientId}`);
  },

  // updateEmploymentVerification
  updateEmploymentVerification(body, clientId, employmentVerificationId) {
    return apiHelper.put(
      `/datatables/Employment verification/${clientId}/${employmentVerificationId}`,
      body
    );
  },

  // deleteEmploymentVerification
  deleteEmploymentVerification(clientId, employmentVerificationId) {
    return apiHelper.delete(
      `/datatables/Employment verification/${clientId}/${employmentVerificationId}`
    );
  },

  // glaccounts
  // getGlAccounts
  getGlAccounts(params = {}) {
    return apiHelper.get(`/glaccounts`, params);
  },

  // getGlAccount
  getGlAccount(glAccountId, params = {}) {
    return apiHelper.get(`/glaccounts/${glAccountId}`, params);
  },

  // imports
  // getImports
  getImports(params = {}) {
    return apiHelper.get(`/imports`, params);
  },

  // insurances
  // createInsurance
  createInsurance(body, officeId) {
    return apiHelper.post(`/datatables/Insurances/${officeId}`, body);
  },

  // getInsurances
  getInsurances(officeId) {
    return apiHelper.get(`/datatables/Insurances/${officeId}`);
  },

  // updateInsurance
  updateInsurance(body, officeId, insuranceId) {
    return apiHelper.put(
      `/datatables/Insurances/${officeId}/${insuranceId}`,
      body
    );
  },

  // investments
  // createInvestment
  createInvestment(body, officeId) {
    return apiHelper.post(`/datatables/Investments/${officeId}`, body);
  },

  // getInvestments
  getInvestments(officeId) {
    return apiHelper.get(`/datatables/Investments/${officeId}`);
  },

  // updateInvestment
  updateInvestment(body, officeId, investmentId) {
    return apiHelper.put(
      `/datatables/Investments/${officeId}/${investmentId}`,
      body
    );
  },

  // journalEntries
  // getJournalEntries
  getJournalEntries(params = {}) {
    return apiHelper.get(`/journalentries`, params);
  },

  // getJournalEntry
  getJournalEntry(journalEntryId, params = {}) {
    return apiHelper.get(`/journalentries/${journalEntryId}`, params);
  },

  // createGlAccount
  createGlAccount(body) {
    return apiHelper.post(`/glaccounts`, body);
  },

  // createJournalEntry
  createJournalEntry(body) {
    return apiHelper.post(`/journalentries`, body);
  },

  // loanProducts
  // getLoanProducts
  getLoanProducts() {
    return apiHelper.get(`/loanproducts`);
  },

  // getLoanProductById
  getLoanProductById(loanProductId) {
    return apiHelper.get(`/loanproducts/${loanProductId}`);
  },

  // createLoanProduct
  createLoanProduct(body) {
    return apiHelper.post(`/loanproducts`, body);
  },

  // updateLoanProduct
  updateLoanProduct(body, loanProductId) {
    return apiHelper.put(`/loanproducts/${loanProductId}`, body);
  },

  // loans
  // getSelfLoan
  getSelfLoan(loanId, params = {}) {
    return apiHelper.get(`/self/loans/${loanId}`, params);
  },

  // getLoanWithAssociations
  getLoanWithAssociations(loanId, params = {}) {
    return apiHelper.get(`/loans/${loanId}`, params);
  },

  // withdrawnLoanByApplican
  withdrawnLoanByApplican(body, loanId) {
    return apiHelper.post(`/loans/${loanId}`, body, {
      command: "withdrawnByApplicant",
    });
  },

  // disburseLoanApplication
  disburseLoanApplication(body, loanId) {
    return apiHelper.post(`/loans/${loanId}`, body, {
      command: "disburse",
    });
  },

  // makeARepayment
  makeARepayment(body, loanId) {
    return apiHelper.post(`/loans/${loanId}/transactions`, body, {
      command: "repayment",
    });
  },

  // paymentTypes
  // getPaymentTypes
  getPaymentTypes() {
    return apiHelper.get(`/paymenttypes`);
  },

  // roles
  // getRoles
  getRoles() {
    return apiHelper.get(`/roles`);
  },

  // savingsAccounts
  // getSavingsAccounts
  getSavingsAccounts() {
    return apiHelper.get(`/savingsaccounts`);
  },

  // getSavingsAccount
  getSavingsAccount(savingsAccountId, params = {}) {
    return apiHelper.get(`/savingsaccounts/${savingsAccountId}`, params);
  },

  // savingsAccountTransactions
  // getSavingsAccountTransaction
  getSavingsAccountTransaction(accountId, transactionId) {
    return apiHelper.get(
      `/savingsaccounts/${accountId}/transactions/${transactionId}`
    );
  },

  // submitNewSavingsApplication
  submitNewSavingsApplication(body) {
    return apiHelper.post(`/savingsaccounts`, body);
  },

  uploadBulkReceipt(body) {
    console.log("helper factory");
    console.log("FormData in helper:", body.get("file")); // Debug log
    return apiHelper.post(
      `/savingsaccounts/1/transactions/bulksavingspayment`,
      body,
      {},
      false, // Always set to true since we're always sending FormData
      true
    );
  },

  // approveSavingsApplication
  approveSavingsApplication(body, accountId) {
    return apiHelper.post(`/savingsaccounts/${accountId}`, body, {
      command: "approve",
    });
  },

  // activateSavingsAccount
  activateSavingsAccount(body, accountId) {
    return apiHelper.post(`/savingsaccounts/${accountId}`, body, {
      command: "activate",
    });
  },

  // withdrawalTransaction
  withdrawalTransaction(body, accountId) {
    return apiHelper.post(`/savingsaccounts/${accountId}/transactions`, body, {
      command: "withdrawal",
    });
  },

  // closeSavingsAccount
  closeSavingsAccount(body, accountId) {
    return apiHelper.post(`/savingsaccounts/${accountId}`, body, {
      command: "close",
    });
  },

  // deleteSavingsAccount
  deleteSavingsAccount(accountId) {
    return apiHelper.delete(`/savingsaccounts/${accountId}`);
  },

  // savingsProducts
  // getSavingsProducts
  getSavingsProducts() {
    return apiHelper.get(`/savingsproducts`);
  },

  // updateSavingsProduct
  updateSavingsProduct(body, savingsProductId) {
    return apiHelper.put(`/savingsproducts/${savingsProductId}`, body);
  },

  // getSavingsProductById
  getSavingsProductById(savingsProductId) {
    return apiHelper.get(`/savingsproducts/${savingsProductId}`);
  },

  // getSavingsProductTable
  getSavingsProductTable(officeId) {
    return apiHelper.get(`/datatables/Savings Settings/${officeId}`);
  },

  // createSavingsProductTable
  createSavingsProductTable(body, officeId) {
    return apiHelper.post(`/datatables/Savings Settings/${officeId}`, body);
  },

  // updateSavingsProductTable
  updateSavingsProductTable(body, officeId) {
    return apiHelper.put(`/datatables/Savings Settings/${officeId}`, body);
  },

  // suspenseAccountTransactions
  // createSuspenseAccountTransaction
  createSuspenseAccountTransaction(body, officeId) {
    return apiHelper.post(
      `/datatables/Suspense Account Transactions/${officeId}`,
      body
    );
  },

  // getSuspenseAccountTransactions
  getSuspenseAccountTransactions(officeId) {
    return apiHelper.get(
      `/datatables/Suspense Account Transactions/${officeId}`
    );
  },

  // updateSuspenseAccountTransaction
  updateSuspenseAccountTransaction(
    body,
    officeId,
    suspenseAccountTransactionId
  ) {
    return apiHelper.put(
      `/datatables/Suspense Account Transactions/${officeId}/${suspenseAccountTransactionId}`,
      body
    );
  },

  // deleteSuspenseAccountTransaction
  deleteSuspenseAccountTransaction(officeId, suspenseAccountTransactionId) {
    return apiHelper.delete(
      `/datatables/Suspense Account Transactions/${officeId}/${suspenseAccountTransactionId}`
    );
  },

  // tasks
  // createTask
  createTask(body, clientId) {
    return apiHelper.post(`/datatables/Tasks/${clientId}`, body);
  },

  // getTasks
  getTasks(clientId) {
    return apiHelper.get(`/datatables/Tasks/${clientId}`);
  },

  // updateTask
  updateTask(body, clientId, taskId) {
    return apiHelper.put(`/datatables/Tasks/${clientId}/${taskId}`, body);
  },

  // deleteTask
  deleteTask(clientId, taskId) {
    return apiHelper.delete(`/datatables/Tasks/${clientId}/${taskId}`);
  },

  // templates
  // getAccountTransfersTemplate
  getAccountTransfersTemplate(params = {}) {
    return apiHelper.get(`/accounttransfers/template`, params);
  },

  // getChargesTemplate
  getChargesTemplate(params = {}) {
    return apiHelper.get(`/charges/template`, params);
  },

  // uploadTemplates
  // uploadUsersTemplate
  uploadUsersTemplate(body) {
    return apiHelper.post(`/users/uploadtemplate`, body, {}, true);
  },

  getUsersTemplateById(id) {
    return apiHelper.get(
      `imports/downloadOutputTemplate?importDocumentId=${id}`
    );
  },

  getUsersTemplateByIdblob(id) {
    return apiHelper.getBlob(
      `imports/downloadOutputTemplate?importDocumentId=${id}`
    );
  },

  // getUsersTemplateById(id) {
  //   return apiHelper.get(
  //     `imports/downloadOutputTemplate?importDocumentId=${id}`,
  //     "arraybuffer"
  //   );
  // },

  // users
  // getUsers
  getUsers() {
    return apiHelper.get(`/users`);
  },

  // wallets
  // createWallet
  createWallet(body, clientId) {
    return apiHelper.post(`/datatables/Wallets/${clientId}`, body);
  },

  // getWallets
  getWallets(clientId) {
    return apiHelper.get(`/datatables/Wallets/${clientId}`);
  },

  // updateWallet
  updateWallet(body, clientId) {
    return apiHelper.put(`/datatables/Wallets/${clientId}`, body);
  },
  // updateWallet
  // updateWallet(body, clientId, walletId) {
  //   return apiHelper.put(`/datatables/Wallets/${clientId}`, body);
  // },

  // deleteWallet
  deleteWallet(clientId, walletId) {
    return apiHelper.delete(`/datatables/Wallets/${clientId}/${walletId}`);
  },

  // accountTransfers
  accountTransfers(params = {}) {
    return apiHelper.get("/accounttransfers", params);
  },

  // accountTransfer
  createAccountTransfer(body) {
    return apiHelper.post("/accounttransfers", body);
  },

  /*
  getUsers(params) {
    return apiHelper.get("/users", params);
  },

  getUser(id) {
    return apiHelper.get(`/users/${id}`);
  },*/

  createUser(body) {
    return apiHelper.post("/users", body);
  },

  /*
  updateUser(id, body) {
    return apiHelper.put(`/users/${id}`, body);
  },

  deleteUser(id) {
    return apiHelper.delete(`/users/${id}`);
  },
  */

  // ...add more endpoints here
};
