import apiHelperFactory from "@/http/apiHelperFactory";
import helpers from "@/plugins/helpers";
import router from "@/router";
import moment from "moment";

const auth = {
  namespaced: true,

  state: {
    // officeId
    officeId: 1,

    // companyName
    // companyName: "Alliance",
    companyName: "Allianceuat",

    // lastAction
    lastAction: moment(),

    // sessionDuration
    sessionDuration: 30,

    // authenticated
    authenticated: false,

    // username
    username: null,

    // firstName
    firstName: null,

    // lastName
    lastName: null,

    // tenantIdentifier
    tenantIdentifier: "develop",

    // save original tenantIdentifier for super and global admins before they log into local admin
    originalTenantIdentifier: "develop",

    // token
    token: null,

    // userId
    userId: null,

    // user
    user: {},

    // userDetails
    userDetails: {},

    // clientId
    clientId: null,

    // clientDetails
    clientDetails: {},

    // permissions
    permissions: null,

    // useAppToken
    useAppToken: false,

    // useAppWebUserToken
    useAppWebUserToken: false,

    // image
    image: null,
  },

  mutations: {
    // setOfficeId
    setOfficeId(state, payload) {
      state.officeId = payload;
    },

    // setCompanyName
    setCompanyName(state, payload) {
      state.companyName = payload;
    },

    // setLastAction
    setLastAction(state, payload) {
      state.lastAction = payload;
    },

    // updateLastAction
    updateLastAction(state) {
      state.lastAction = moment();
    },

    // setSessionDuration
    setSessionDuration(state, payload) {
      state.sessionDuration = payload;
    },

    // setAuthenticated
    setAuthenticated(state, payload) {
      state.authenticated = payload;
    },

    // setUsername
    setUsername(state, payload) {
      state.username = payload;
    },

    // setFirstName
    setFirstName(state, payload) {
      state.firstName = payload;
    },

    // setLastName
    setLastName(state, payload) {
      state.lastName = payload;
    },

    // setTenantIdentifier
    setTenantIdentifier(state, payload) {
      state.tenantIdentifier = payload;
    },

    // setToken
    setToken(state, payload) {
      state.token = payload;
    },

    // setUserId
    setUserId(state, payload) {
      state.userId = payload;
    },

    // setUser
    setUser(state, payload) {
      state.user = payload;
    },

    // setUserDetails
    setUserDetails(state, payload) {
      state.userDetails = payload;
    },

    // setClientId
    setClientId(state, payload) {
      state.clientId = payload;
    },

    // setClientDetails
    setClientDetails(state, payload) {
      // clientId
      if ("id" in payload) {
        state.clientId = payload.id;
      }

      // firstName
      if ("firstname" in payload) {
        state.firstName = payload.firstname;
      }

      // lastName
      if ("lastname" in payload) {
        state.lastName = payload.lastname;
      }

      state.clientDetails = payload;
    },

    // setPermissions
    setPermissions(state, payload) {
      state.permissions = payload;
    },
    // setPermissions
    setImage(state, payload) {
      state.image = payload;
    },

    // setUseAppToken
    setUseAppToken(state, payload) {
      // useAppToken
      state.useAppToken = payload;
      state.useAppToken = false;

      // roleNameToCheck
      const roleNameToCheck = "Member";

      // user
      const user = state.user;

      if (
        user &&
        user.roles &&
        Array.isArray(user.roles) &&
        user.roles.length > 0
      ) {
        // hasRole
        const hasRole = user.roles.some(
          (role) => role.name === roleNameToCheck
        );

        if (hasRole) {
          state.useAppToken = false;
        }
      }

      // permissions
      if (state.permissions) {
        // isPresent
        const isPresent = state.permissions.includes("ALL_FUNCTIONS");

        if (payload && isPresent) {
          state.useAppToken = false;
        }
      }
    },

    // setUseAppWebUserToken
    setUseAppWebUserToken(state, payload) {
      // useAppWebUserToken
      state.useAppWebUserToken = payload;
    },

    // setAuth
    setAuth(state, payload) {
      // authenticated
      if ("authenticated" in payload) {
        state.authenticated = payload.authenticated;
      } else {
        state.authenticated = true;
      }

      // username
      if ("username" in payload) {
        state.username = payload.username;
      }

      // token
      if ("base64EncodedAuthenticationKey" in payload) {
        state.token = payload.base64EncodedAuthenticationKey;
      }

      // userId
      if ("userId" in payload) {
        state.userId = payload.userId;
      }

      // officeId
      if ("officeId" in payload) {
        state.officeId = payload.officeId;
      }

      // permissions
      if ("permissions" in payload) {
        state.permissions = payload.permissions;
      }

      // useAppToken
      state.useAppToken = false;

      // useAppWebUserToken
      state.useAppWebUserToken = false;

      // user
      state.user = payload;
    },

    // setImage
    resetImage(state) {
      state.image = null;
    },

    // resetState
    resetState(state) {
      // officeId
      state.officeId = 1;

      // companyName
      state.companyName = "Alliance";

      // lastAction
      state.lastAction = moment();

      // sessionDuration
      state.sessionDuration = 30;

      // authenticated
      state.authenticated = false;

      // username
      state.username = null;

      // firstName
      state.firstName = null;

      // lastName
      state.lastName = null;

      // tenantIdentifier
      state.tenantIdentifier = "develop";

      state.originalTenantIdentifier = "develop";

      // token
      state.token = null;

      // userId
      state.userId = null;

      // user
      state.user = {};

      // userDetails
      state.userDetails = {};

      // clientId
      state.clientId = null;

      // clientDetails
      state.clientDetails = {};

      // permissions
      state.permissions = null;

      // useAppToken
      state.useAppToken = false;

      // useAppWebUserToken
      state.useAppWebUserToken = false;

      // image
      state.image = null;
    },
  },

  actions: {
    // getClientDetailsAndUserDetails
    async getClientDetailsAndUserDetails({ commit, state }) {
      try {
        const listClientsSelfRes = await apiHelperFactory.listClientsSelf();

        if (
          "pageItems" in listClientsSelfRes.data &&
          listClientsSelfRes.data.pageItems
        ) {
          commit("setClientDetails", listClientsSelfRes.data.pageItems[0]);
        }

        // setUseAppToken
        commit("setUseAppToken", true);

        const retrieveAUserRes = await apiHelperFactory.retrieveAUser(
          state.userId
        );
        console.log("retrieveAUserRes:::::", retrieveAUserRes);

        // setUserDetails
        commit("setUserDetails", retrieveAUserRes.data);
      } catch (error) {
        if (error.response) {
          // handle response error
        } else if (error.request) {
          // handle request error
        } else {
          // handle other errors
        }
      } finally {
        commit("setUseAppToken", false);
      }
    },

    // getClientDetails
    async getClientDetails({ commit }) {
      // startLoading
      commit("app/startLoading", null, { root: true });

      try {
        // listClientsSelfRes
        const listClientsSelfRes = await apiHelperFactory.listClientsSelf();

        // setClientDetails
        if (
          "pageItems" in listClientsSelfRes.data &&
          listClientsSelfRes.data.pageItems
        ) {
          commit("setClientDetails", listClientsSelfRes.data.pageItems[0]);
        }

        return listClientsSelfRes;
      } catch (error) {
        if (error.response) {
          // handle response error
        } else if (error.request) {
          // handle request error
        } else {
          // handle other errors
        }
      } finally {
        // stopLoading
        commit("app/stopLoading", null, { root: true });
      }
    },

    // getUserDetails
    async getUserDetails({ commit, state }) {
      // startLoading
      commit("app/startLoading", null, { root: true });

      // setUseAppToken
      commit("setUseAppToken", true);

      try {
        // retrieveAUserRes
        const retrieveAUserRes = await apiHelperFactory.retrieveAUser(
          state.userId
        );

        // setUserDetails
        commit("setUserDetails", retrieveAUserRes.data);

        return retrieveAUserRes;
      } catch (error) {
        if (error.response) {
          // handle response error
        } else if (error.request) {
          // handle request error
        } else {
          // handle other errors
        }
      } finally {
        // stopLoading
        commit("app/stopLoading", null, { root: true });

        // setUseAppToken
        commit("setUseAppToken", false);
      }
    },

    // getLoansReq
    async getLoansReq({ commit }, payload) {
      // startLoading
      commit("app/startLoading", null, { root: true });

      try {
        // params
        let params = {};
        params = payload;

        // getLoansRes
        const getLoansRes = await apiHelperFactory.getLoans(params);

        return getLoansRes;
      } catch (error) {
        if (error.response) {
          let res = error.response;

          // handleError
          helpers.handleError(res.status, res.data);
        } else {
          // handleError
          helpers.handleError();
        }
      } finally {
        // stopLoading
        commit("app/stopLoading", null, { root: true });
      }
    },

    // getLoanReq
    async getLoanReq({ commit }, payload) {
      // startLoading
      commit("app/startLoading", null, { root: true });

      try {
        // loanId
        const loanId = payload.loanId;

        // getLoansRes
        const getLoanRes = await apiHelperFactory.getLoan(loanId);

        return getLoanRes;
      } catch (error) {
        if (error.response) {
          let res = error.response;

          // handleError
          helpers.handleError(res.status, res.data);
        } else {
          // handleError
          helpers.handleError();
        }
      } finally {
        // stopLoading
        commit("app/stopLoading", null, { root: true });
      }
    },

    // loginReq
    async loginReq({ commit }, payload) {
      commit("app/startLoading", null, { root: true });

      try {
        // loginRes
        const loginRes = await apiHelperFactory.login(payload);

        // setAuth
        commit("setAuth", loginRes.data);

        return loginRes;
      } catch (error) {
        // error.response
        if (error.response) {
          let res = error.response;

          if (res.status === 400) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 401) {
            // handleError
            helpers.handleError(
              res.status,
              res.data,
              "Invalid Credentials",
              "Username or password incorrect. Please try again."
            );
          } else if (res.status === 403) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 404) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 500) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else {
            // handleError
            helpers.handleError(res.status, res.data);
          }
        } else {
          // handleError
          helpers.handleError();
        }
      } finally {
        // stopLoading
        commit("app/stopLoading", null, { root: true });
      }
    },

    // loginMainReq
    async loginMainReq({ commit }, payload) {
      commit("app/startLoading", null, { root: true });

      try {
        // loginRes
        const loginRes = await apiHelperFactory.login(payload);

        // setAuth
        commit("setAuth", loginRes.data);

        return loginRes;
      } catch (error) {
        // error.response
        if (error.response) {
          //
        } else {
          // handleError
        }
      } finally {
        // stopLoading
        commit("app/stopLoading", null, { root: true });
      }
    },

    // changePasswordReq
    async changePasswordReq({ commit }, payload) {
      // startLoading
      commit("app/startLoading", null, { root: true });

      try {
        // loginRes
        const loginRes = await apiHelperFactory.login(payload);

        // setAuth
        commit("setAuth", loginRes.data);

        return loginRes;
      } catch (error) {
        console.log("changePasswordReq error", error);

        if (error.response) {
          let res = error.response;

          if (res.status === 400) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 401) {
            // handleError
            helpers.handleError(
              res.status,
              res.data,
              "Invalid Credentials",
              "Current password incorrect. Please try again."
            );
          } else if (res.status === 403) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 404) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 500) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else {
            // handleError
            helpers.handleError(res.status, res.data);
          }
        } else {
          // handleError
          helpers.handleError();
        }
      } finally {
        // stopLoading
        commit("app/stopLoading", null, { root: true });
      }
    },

    // updateUserReq
    async updateUserReq({ commit }, { payload, userId }) {
      // startLoading
      commit("app/startLoading", null, { root: true });

      // setUseAppToken
      commit("setUseAppToken", true);

      try {
        return await apiHelperFactory.updateUserDetails(payload, userId);
      } catch (error) {
        // error.response
        if (error.response) {
          let res = error.response;

          if (res.status === 400) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 401) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 403) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 404) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 500) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else {
            // handleError
            helpers.handleError(res.status, res.data);
          }
        } else {
          // handleError
          helpers.handleError();
        }
      } finally {
        // setUseAppToken
        commit("setUseAppToken", false);

        // stopLoading
        commit("app/stopLoading", null, { root: true });
      }
    },

    // submitANewLoanApplicationReq
    async submitANewLoanApplicationReq({ commit }, payload) {
      // startLoading
      commit("app/startLoading", null, { root: true });

      // setUseAppToken
      commit("setUseAppToken", true);

      try {
        return await apiHelperFactory.submitANewLoanApplication(payload);
      } catch (error) {
        // error.response
        if (error.response) {
          let res = error.response;

          if (res.status === 400) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 401) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 403) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 404) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 500) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else {
            // handleError
            helpers.handleError(res.status, res.data);
          }
        } else {
          // handleError
          helpers.handleError();
        }
      } finally {
        // setUseAppToken
        commit("setUseAppToken", false);

        // stopLoading
        commit("app/stopLoading", null, { root: true });
      }
    },

    // approveLoanApplicationReq
    async approveLoanApplicationReq({ commit }, { payload, loanId }) {
      // startLoading
      commit("app/startLoading", null, { root: true });

      // setUseAppToken
      commit("setUseAppToken", true);

      try {
        return await apiHelperFactory.approveLoanApplication(payload, loanId);
      } catch (error) {
        // error.response
        if (error.response) {
          let res = error.response;

          if (res.status === 400) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 401) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 403) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 404) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 500) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else {
            // handleError
            helpers.handleError(res.status, res.data);
          }
        } else {
          // handleError
          helpers.handleError();
        }
      } finally {
        // setUseAppToken
        commit("setUseAppToken", false);

        // stopLoading
        commit("app/stopLoading", null, { root: true });
      }
    },

    // rejectLoanApplicationReq
    async rejectLoanApplicationReq({ commit }, { payload, loanId }) {
      // startLoading
      commit("app/startLoading", null, { root: true });

      // setUseAppToken
      commit("setUseAppToken", true);

      try {
        return await apiHelperFactory.rejectLoanApplication(payload, loanId);
      } catch (error) {
        // error.response
        if (error.response) {
          let res = error.response;

          if (res.status === 400) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 401) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 403) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 404) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 500) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else {
            // handleError
            helpers.handleError(res.status, res.data);
          }
        } else {
          // handleError
          helpers.handleError();
        }
      } finally {
        // setUseAppToken
        commit("setUseAppToken", false);

        // stopLoading
        commit("app/stopLoading", null, { root: true });
      }
    },

    // logOut
    async logOut({ commit }) {
      // startLoading
      commit("app/startLoading", null, { root: true });

      // resetState
      commit("resetState");

      // alert/resetState
      commit("alert/resetState", null, { root: true });

      commit("inboxApiBank/resetState", null, { root: true });

      // app/resetState
      commit("app/resetState", null, { root: true });

      // mobileSidebar/resetState
      commit("mobileSidebar/resetState", null, { root: true });

      // snackbar/resetState
      commit("snackbar/resetState", null, { root: true });

      // accountTransferApi/resetState
      commit("accountTransferApi/resetState", null, { root: true });

      // allocationApi/resetState
      commit("allocationApi/resetState", null, { root: true });

      // constitutionApiBank
      commit("constitutionApiBank/resetState", null, { root: true });

      // bankingDetailApi/resetState
      commit("bankingDetailApi/resetState", null, { root: true });

      // chargeApi/resetState
      commit("chargeApi/resetState", null, { root: true });

      // clientDetailApi/resetState
      commit("clientDetailApi/resetState", null, { root: true });

      // clientApi/resetState
      commit("clientApi/resetState", null, { root: true });

      // codeValueApi/resetState
      commit("codeValueApi/resetState", null, { root: true });

      // codeApi/resetState
      commit("codeApi/resetState", null, { root: true });

      commit("stokvelApiBank/resetState", null, { root: true });

      // contributionApi/resetState
      commit("contributionApi/resetState", null, { root: true });

      // datatableApi/resetState
      commit("datatableApi/resetState", null, { root: true });

      // downloadTemplateApi/resetState
      commit("downloadTemplateApi/resetState", null, { root: true });

      // employmentVerificationApi/resetState
      commit("employmentVerificationApi/resetState", null, { root: true });

      // glAccountApi/resetState
      commit("glAccountApi/resetState", null, { root: true });

      // importApi/resetState
      commit("importApi/resetState", null, { root: true });

      // insuranceApi/resetState
      commit("insuranceApi/resetState", null, { root: true });

      // investmentApi/resetState
      commit("investmentApi/resetState", null, { root: true });

      // journalEntryApi/resetState
      commit("journalEntryApi/resetState", null, { root: true });

      // loanProductApi/resetState
      commit("loanProductApi/resetState", null, { root: true });

      // loanApi/resetState
      commit("loanApi/resetState", null, { root: true });

      // noteApi/resetState
      commit("noteApi/resetState", null, { root: true });

      // notificationApi/resetState
      commit("notificationApi/resetState", null, { root: true });

      // paymentTypeApi/resetState
      commit("paymentTypeApi/resetState", null, { root: true });

      // roleApi/resetState
      commit("roleApi/resetState", null, { root: true });

      // savingsAccountApi/resetState
      commit("savingsAccountApi/resetState", null, { root: true });

      // savingsAccountTransactionApi/resetState
      commit("savingsAccountTransactionApi/resetState", null, { root: true });

      // savingsProductApi/resetState
      commit("savingsProductApi/resetState", null, { root: true });

      // suspenseAccountTransactionApi/resetState
      commit("suspenseAccountTransactionApi/resetState", null, { root: true });

      // taskApi/resetState
      commit("taskApi/resetState", null, { root: true });

      // templateApi/resetState
      commit("templateApi/resetState", null, { root: true });

      // uploadTemplateApi/resetState
      commit("uploadTemplateApi/resetState", null, { root: true });

      // uploadTemplateApi/fullResetState
      commit("uploadTemplateApi/fullResetState", null, { root: true });

      // userApi/resetState
      commit("userApi/resetState", null, { root: true });

      // walletApi/resetState
      commit("walletApi/resetState", null, { root: true });

      // apiBank/resetState
      commit("apiBank/resetState", null, { root: true });

      // authBank/resetState
      commit("authBank/resetState", null, { root: true });

      // tenantApiBank/resetState
      commit("tenantApiBank/resetState", null, { root: true });

      commit("investmentApiBank/resetState", null, { root: true });

      commit("transactionApiBank/resetState", null, { root: true });

      // adminForgotPassword/resetState
      commit("adminForgotPassword/resetState", null, { root: true });

      // adminLogin/resetState
      commit("adminLogin/resetState", null, { root: true });

      // adminInvitationLogin/resetState
      commit("adminInvitationLogin/resetState", null, { root: true });

      // adminResetPassword/resetState
      commit("adminResetPassword/resetState", null, { root: true });

      // adminSignUp/resetState
      commit("adminSignUp/resetState", null, { root: true });

      // adminStokvelApplication/resetState
      commit("adminStokvelApplication/resetState", null, { root: true });

      // authSliders/resetState
      commit("authSliders/resetState", null, { root: true });

      // memberForgotPassword/resetState
      commit("memberForgotPassword/resetState", null, { root: true });

      // memberLogin/resetState
      commit("memberLogin/resetState", null, { root: true });

      // memberResetPassword/resetState
      commit("memberResetPassword/resetState", null, { root: true });

      // memberSignUp/resetState
      commit("memberSignUp/resetState", null, { root: true });

      // memberInvite/resetState
      commit("memberInvite/resetState", null, { root: true });

      // contributions/resetState
      commit("contributions/resetState", null, { root: true });

      // insurance/resetState
      commit("insurance/resetState", null, { root: true });

      // administration/resetState
      commit("administration/resetState", null, { root: true });

      // applyForALoan/resetState
      commit("applyForALoan/resetState", null, { root: true });

      // getAQuote/resetState
      commit("getAQuote/resetState", null, { root: true });

      // loanDetails/resetState
      commit("loanDetails/resetState", null, { root: true });

      // transaction/resetState
      commit("transaction/resetState", null, { root: true });

      // stokvelAccount/resetState
      commit("stokvelAccount/resetState", null, { root: true });

      // loanProcessing/resetState
      commit("loanProcessing/resetState", null, { root: true });

      // investmentProcessing/resetState
      commit("investmentProcessing/resetState", null, { root: true });

      // personalAccounts/resetState
      commit("personalAccounts/resetState", null, { root: true });

      // savings/resetState
      commit("savings/resetState", null, { root: true });

      // stokvelProcessing/resetState
      commit("stokvelProcessing/resetState", null, { root: true });

      // adminTransaction/resetState
      commit("adminTransaction/resetState", null, { root: true });

      // adminSetting/resetState
      commit("adminSetting/resetState", null, { root: true });

      // policiesAndSystem/resetState
      commit("policiesAndSystem/resetState", null, { root: true });

      // globalAdminTransaction/resetState
      commit("globalAdminTransaction/resetState", null, { root: true });

      // message/resetState
      commit("message/resetState", null, { root: true });

      // stopLoading
      commit("app/stopLoading", null, { root: true });
    },

    // logOutAuto
    async logOutAuto({ dispatch, state }) {
      if (state.authenticated) {
        // logOut
        await dispatch("logOut");

        // Home
        router.push({ name: "Home" });
      }
    },

    // updateLastAction
    updateLastAction({ commit }) {
      // Add a delay of 3 seconds before committing the mutation
      setTimeout(() => {
        commit("updateLastAction");
      }, 3000); // 3000 milliseconds = 3 seconds
    },

    // setSessionDuration
    setSessionDuration({ commit }, payload) {
      commit("setSessionDuration", payload);
    },

    // setUpUserData
    async setUpUserData({ dispatch, state }) {
      // clientId
      const clientId = state.clientId;

      // getUsersReq
      dispatch(
        "userApi/getUsersReq",
        {
          useLoading: false,
        },
        { root: true }
      );

      // getLoanProductsReq
      dispatch(
        "loanProductApi/getLoanProductsReq",
        {
          useLoading: false,
        },
        { root: true }
      );

      if (clientId) {
        // getContributionsReq
        dispatch(
          "contributionApi/getContributionsReq",
          {
            clientId: clientId,
            useLoading: false,
          },
          { root: true }
        );

        // getBankingDetailsReq
        dispatch(
          "bankingDetailApi/getBankingDetailsReq",
          {
            clientId: clientId,
            useLoading: false,
          },
          { root: true }
        );

        // getEmploymentVerificationsReq
        dispatch(
          "employmentVerificationApi/getEmploymentVerificationsReq",
          {
            clientId: clientId,
            useLoading: false,
          },
          { root: true }
        );

        // getWalletsReq
        dispatch(
          "walletApi/getWalletsReq",
          {
            clientId: clientId,
            useLoading: false,
          },
          { root: true }
        );
      }

      // getCodesReq
      await dispatch(
        "codeApi/getCodesReq",
        {
          useLoading: false,
        },
        { root: true }
      );

      // getCodeValuesByCodeName
      dispatch(
        "codeValueApi/getCodeValuesByCodeName",
        {
          codeName: "Contribution Types",
          useLoading: false,
        },
        { root: true }
      );

      // getCodeValuesByCodeName
      dispatch(
        "codeValueApi/getCodeValuesByCodeName",
        {
          codeName: "Account Type",
          useLoading: false,
        },
        { root: true }
      );

      // getCodeValuesByCodeName
      dispatch(
        "codeValueApi/getCodeValuesByCodeName",
        {
          codeName: "Banks",
          useLoading: false,
        },
        { root: true }
      );

      // getCodeValuesByCodeName
      dispatch(
        "codeValueApi/getCodeValuesByCodeName",
        {
          codeName: "Wallet Providers",
          useLoading: false,
        },
        { root: true }
      );

      if (clientId) {
        // getClientAccountsOverviewReq
        await dispatch(
          "clientApi/getClientAccountsOverviewReq",
          {
            clientId: clientId,
            useLoading: false,
          },
          { root: true }
        );

        // getSavingsProductsReq
        await dispatch(
          "savingsProductApi/getSavingsProductsReq",
          {
            useLoading: false,
          },
          { root: true }
        );
      }
    },
  },

  getters: {
    // getOfficeId
    getOfficeId(state) {
      return state.officeId;
    },

    // getCompanyName
    getCompanyName(state) {
      return state.companyName;
    },

    // getLastAction
    getLastAction(state) {
      return state.lastAction;
    },

    // getSessionDuration
    getSessionDuration(state) {
      return state.sessionDuration;
    },

    // getAuthenticated
    getAuthenticated(state) {
      return state.authenticated;
    },

    // getUsername
    getUsername(state) {
      return state.username;
    },

    // getUsernameDisplay
    getUsernameDisplay(state, getters, rootState, rootGetters) {
      // username
      let username = state.username ? state.username : "Unknown Username";

      // authenticated
      const authenticated = rootState.authBank.authenticated;

      if (authenticated) {
        username = rootGetters["authBank/getUsername"];
      }

      return helpers.truncateString(username, 22);
    },

    // getUsernameDisplayMain
    getUsernameDisplayMain:
      (state, getters, rootState, rootGetters) =>
      (length = 22) => {
        // username
        let username = state.username ? state.username : "Unknown Username";

        // authenticated
        const authenticated = rootState.authBank.authenticated;

        if (authenticated) {
          username = rootGetters["authBank/getUsername"];
        }

        return helpers.truncateString(username, length);
      },

    // getFirstName
    getFirstName(state) {
      return state.firstName;
    },

    // getFirstNameDisplay
    getFirstNameDisplay(state, getters, rootState, rootGetters) {
      // firstName
      let firstName = state.firstName ? state.firstName : "Unknown Name";

      // authenticated
      const authenticated = rootState.authBank.authenticated;

      if (authenticated) {
        firstName = rootGetters["authBank/getFirstName"];
      }

      return helpers.truncateString(firstName, 10);
    },

    // getLastName
    getLastName(state) {
      return state.lastName;
    },

    // getTenantIdentifier
    getTenantIdentifier(state) {
      return state.tenantIdentifier;
    },

    // getToken
    getToken(state) {
      return state.token;
    },

    // getUserId
    getUserId(state) {
      return state.userId;
    },

    // getUser
    getUser(state) {
      return state.user;
    },

    // getUserDetails
    getUserDetails(state) {
      return state.userDetails;
    },

    // getClientId
    getClientId(state) {
      return state.clientId;
    },

    // getClientDetails
    getClientDetails(state) {
      return state.clientDetails;
    },

    // getPermissions
    getPermissions(state) {
      return state.permissions;
    },

    // getUseAppToken
    getUseAppToken(state) {
      return state.useAppToken;
    },

    // getUseAppWebUserToken
    getUseAppWebUserToken(state) {
      return state.useAppWebUserToken;
    },

    // getInitials
    getInitials(state) {
      if (state.firstName && state.lastName) {
        return state.firstName[0] + state.lastName[0];
      }

      return "";
    },

    // getFullName
    getFullName(state) {
      // fullName
      let fullName = "";

      // firstName
      if (state.firstName) {
        fullName += state.firstName;
      }

      // lastName
      if (state.lastName) {
        if (fullName.length > 0) {
          fullName += " ";
        }
        fullName += state.lastName;
      }

      return fullName;
    },

    // getImage
    getImage(state) {
      return state.image;
    },

    // getRole
    getRole(state) {
      if (state.user.roles && state.user.roles.length > 0) {
        return state.user.roles[0];
      }

      return null;
    },

    getRoleName(state, getters) {
      // role
      const role = getters.getRole;

      if (role && "name" in role) {
        return role.name;
      }

      return null;
    },

    // getIsSuperUser
    getIsSuperUser(state) {
      // Check if "ALL_FUNCTIONS" is in permissions and there are no other permissions
      return (
        state.permissions !== null &&
        state.permissions.includes("ALL_FUNCTIONS") &&
        state.permissions.length === 1
      );
    },

    // getCan
    getCan: (state) => (permissionName) => {
      return (
        state.permissions !== null && state.permissions.includes(permissionName)
      );
    },
  },
};

export default auth;
