import apiHelperFactoryForBank from "@/http/apiHelperFactoryForBank";
import helpers from "@/plugins/helpers";
// import router from "@/router";
// import moment from "moment";

const authBank = {
  namespaced: true,

  state: {
    // authenticated
    authenticated: false,

    // token
    token: null,

    // role
    role: "",

    // userId
    userId: null,

    // userById
    userById: {},
    // user
    user: {},
  },

  mutations: {
    // setAuthenticated
    setAuthenticated(state, payload) {
      state.authenticated = payload;
    },

    // setUserById
    setUserById(state, payload) {
      state.userById = payload;
    },
    // setToken
    setToken(state, payload) {
      state.token = payload;
    },

    // setRole
    setRole(state, payload) {
      state.role = payload;
    },

    // setUserId
    setUserId(state, payload) {
      state.userId = payload;
    },

    // setUser
    setUser(state, payload) {
      state.user = payload;
    },

    // setAuth
    setAuth(state, payload) {
      // token
      if ("token" in payload) {
        state.token = payload.token;
      }
      
      // role
      if ("role" in payload & state.role != "localadmin") {
        state.role = payload.role;
      }

      // id
      if ("id" in payload) {
        state.userId = payload.id;
      }

      // authenticated
      state.authenticated = true;

      // user
      state.user = payload;
    },

    // setChangePassword
    setChangePassword(state, payload) {
      // token
      if ("token" in payload) {
        state.token = payload.token;
        state.user.jwtToken = payload.token;
        state.user.token = payload.token;
      }
    },

    // resetState
    resetState(state) {
      // authenticated
      state.authenticated = false;

      // UserById
      state.userById = {};

      // token
      state.token = null;

      // role
      state.role = "";

      // userId
      state.userId = null;

      // user
      state.user = {};
    },
  },

  actions: {
    // eslint-disable-next-line no-unused-vars
    async getUserId({ commit, state, rootState }) {
      // startLoading
      commit("app/startLoading", null, { root: true });

      try {
        const userId = state.userId;
        // retrieveAUserRes
        const retrieveAUserRes = await apiHelperFactoryForBank.retrieveAUser(
          userId
        );

        // setUserDetails
        commit("setUserById", retrieveAUserRes.data);

        return retrieveAUserRes;
      } catch (error) {
        if (error.response) {
          // handle response error
        } else if (error.request) {
          // handle request error
        } else {
          // handle other errors
        }
      } finally {
        // stopLoading
        commit("app/stopLoading", null, { root: true });
      }
    },

    // loginReq
    async loginReq({ commit }, payload = {}) {
      // useLoading
      const useLoading = "useLoading" in payload ? payload.useLoading : true;

      // useLoading
      if (useLoading) {
        // startLoading
        commit("app/startLoading", null, { root: true });
      }

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        // loginRes
        const loginRes = await apiHelperFactoryForBank.login(payload.body);

        // setAuth
        commit("setAuth", loginRes.data);

        return loginRes;
      } catch (error) {
        // error.response
        if (error.response) {
          let res = error.response;

          if (res.status === 400) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 401) {
            // handleError
            helpers.handleError(
              res.status,
              res.data,
              "Invalid Credentials",
              "Username or password incorrect. Please try again."
            );
          } else if (res.status === 403) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 404) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 500) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else {
            // handleError
            helpers.handleError(res.status, res.data);
          }
        } else {
          // handleError
          helpers.handleError();
        }
      } finally {
        // useLoading
        if (useLoading) {
          // stopLoading
          commit("app/stopLoading", null, { root: true });
        }

        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });
      }
    },

    // updateUserReq
    async updateUserReq({ commit }, payload = {}) {
      // useLoading
      const useLoading = "useLoading" in payload ? payload.useLoading : true;

      // useLoading
      if (useLoading) {
        // startLoading
        commit("app/startLoading", null, { root: true });
      }

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        // updateUserRes
        const updateUserRes = await apiHelperFactoryForBank.updateUser(
          payload.body,
          payload.userId
        );

        // setAuth
        commit("setAuth", updateUserRes.data);

        return updateUserRes;
      } catch (error) {
        // error.response
        if (error.response) {
          let res = error.response;

          if (res.status === 400) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 401) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 403) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 404) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 500) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else {
            // handleError
            helpers.handleError(res.status, res.data);
          }
        } else {
          // handleError
          helpers.handleError();
        }
      } finally {
        // useLoading
        if (useLoading) {
          // stopLoading
          commit("app/stopLoading", null, { root: true });
        }

        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });
      }
    },

    // changePasswordReq
    async changePasswordReq({ commit }, payload = {}) {
      // useLoading
      const useLoading = "useLoading" in payload ? payload.useLoading : true;

      // useLoading
      if (useLoading) {
        // startLoading
        commit("app/startLoading", null, { root: true });
      }

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        // changePasswordRes
        const changePasswordRes = await apiHelperFactoryForBank.changePassword(
          payload.body
        );

        // setChangePassword
        commit("setChangePassword", changePasswordRes.data);

        return changePasswordRes;
      } catch (error) {
        // error.response
        if (error.response) {
          let res = error.response;

          if (res.status === 400) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 401) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 403) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 404) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 500) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else {
            // handleError
            helpers.handleError(res.status, res.data);
          }
        } else {
          // handleError
          helpers.handleError();
        }
      } finally {
        // useLoading
        if (useLoading) {
          // stopLoading
          commit("app/stopLoading", null, { root: true });
        }

        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });
      }
    },

    // forgotPasswordReq
    async forgotPasswordReq({ commit }, { payload }) {
      // useLoading
      const useLoading = "useLoading" in payload ? payload.useLoading : true;

      // useLoading
      if (useLoading) {
        // startLoading
        commit("app/startLoading", null, { root: true });
      }

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        // forgotPasswordRes
        const forgotPasswordRes = await apiHelperFactoryForBank.forgotPassword(
          payload
        );

        // forgotPasswordRes
        return forgotPasswordRes;
      } catch (error) {
        // error.response
        if (error.response) {
          let res = error.response;

          if (res.status === 400) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 401) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 403) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 404) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 500) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else {
            // handleError
            helpers.handleError(res.status, res.data);
          }
        } else {
          // handleError
          helpers.handleError();
        }
      } finally {
        // useLoading
        if (useLoading) {
          // stopLoading
          commit("app/stopLoading", null, { root: true });
        }

        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });
      }
    },

    // resetPasswordReq
    async resetPasswordReq({ commit }, { payload }) {
      // useLoading
      const useLoading = "useLoading" in payload ? payload.useLoading : true;

      // useLoading
      if (useLoading) {
        // startLoading
        commit("app/startLoading", null, { root: true });
      }

      // setUseAppToken
      commit("auth/setUseAppToken", true, { root: true });

      try {
        // resetPasswordRes
        const resetPasswordRes = await apiHelperFactoryForBank.resetPassword(
          payload
        );

        // resetPasswordRes
        return resetPasswordRes;
      } catch (error) {
        // error.response
        if (error.response) {
          let res = error.response;

          if (res.status === 400) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 401) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 403) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 404) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else if (res.status === 500) {
            // handleError
            helpers.handleError(res.status, res.data);
          } else {
            // handleError
            helpers.handleError(res.status, res.data);
          }
        } else {
          // handleError
          helpers.handleError();
        }
      } finally {
        // useLoading
        if (useLoading) {
          // stopLoading
          commit("app/stopLoading", null, { root: true });
        }

        // setUseAppToken
        commit("auth/setUseAppToken", false, { root: true });
      }
    },
  },

  getters: {
    // getAuthenticated
    getAuthenticated(state) {
      return state.authenticated;
    },

    // getToken
    getToken(state) {
      return state.token;
    },
    // getuserById
    getUserBase64(state) {
      return state.userById.base64;
    },

    // getRole
    getRole(state) {
      return state.role;
    },

    // getUserId
    getUserId(state) {
      return state.userId;
    },

    // getUser
    getUser(state) {
      return state.user;
    },

    // getIsSuperAdmin
    getIsSuperAdmin(state) {
      return state.role === "Super Admin";
    },

    // getIsAdmin
    getIsAdmin(state) {
      return state.role;
    },

    // getFirstName
    getFirstName(state) {
      return state.user && "firstName" in state.user
        ? state.user.firstName
        : "Unknown First Name";
    },

    // getFullName
    getFullName(state) {
      return state.user && "firstName" in state.user && "lastName" in state.user
        ? state.user.firstName + " " + state.user.lastName
        : "Unknown Full Name";
    },

    // getUsername
    getUsername(state) {
      return state.user && "username" in state.user
        ? state.user.username
        : "Unknown Username";
    },
  },
};

export default authBank;
