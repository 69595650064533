import axios from "axios";

// const baseURL = process.env.VUE_APP_BANK_API_BASE_URL.replace("/api", "");
//const baseURL = "https://bankapilayer.softidoc.co.za";

const baseDomain = process.env.VUE_APP_BANK_API_BASE_URL.replace("/api", "");
const baseURL = `${baseDomain}`;

const bankAxios = axios.create({
  baseURL,
});

// No need to set the ApiKey header
bankAxios.defaults.headers.common = {
  "Access-Control-Allow-Origin": "*",
  "Content-Type": "application/json",
};

export default bankAxios;
